import template from './parFormCheckboxRadioList.html'

export default (Vue, blockHelper) => {

    Vue.component('form-checkbox-radio-list', {
        mixins: [blockHelper.compile(template)],
        data: function() {
            return {
                isOpen: false,
                lastSelectedElement: undefined,
                selectedValues: this.vm.defaultValues
            }
        },
        mounted() {
            this.resize();
        },
        computed: {
            hasSelected() {
                return this.selectedValues.length > 0;
            },
            selectedCount() {
                return this.selectedValues.length;
            },
            selectedLabel() {
                if(!this.isCheckboxList) {
                    var el = this.vm.elements
                        .find(x => this.selectedValues.includes(x.value)) 
                    return el ? el.label : '';
                }
                else if(!this.hasSelected) {
                    return 'None';
                }
                else if(this.hasSelected && this.vm.elements[0].value == this.vm.elements[0]) {
                    return this.vm.elements[0].label;
                }
                else if(this.selectedCount == 1) {
                    return this.vm.elements
                        .find(x => this.selectedValues[0] === x.value).label;
                }
                else {
                    return 'Multiple Applied';
                }
            },
            isCheckboxList() {
                return this.vm.elements.length > 0 && this.vm.elements[0].type === 'checkbox';
            }
        },
        methods: {
            selectValue: function(element, newSelectedValues) 
            {
                if(newSelectedValues) {
                    this.lastSelectedElement = element;
                    this.selectedValues = newSelectedValues;
                    let selectedElements = this.vm.elements.filter((e) => { 
                        return newSelectedValues.includes(e.value); 
                    });
                    this.toggle();
                    this.$emit('select-value', this.vm.name, this.selectedValues, selectedElements);
                }
            },
            toggle: function() {
                this.isOpen = !this.isOpen;
                this.$emit('select-open', this.isOpen);
            },
            resize: function() {
                if(this.$el.querySelector) {
                    this.inputsWrapper = this.$el.querySelector('[data-inputs-wrapper]');
                    const observer = new ResizeObserver(entries => {
                        for (let entry of entries) {
                            entry.target.style.setProperty('--height', (entry.target.scrollHeight + 1) + "px");
                        }
                    });
                    observer.observe(this.inputsWrapper);
                }
            },
        },
        props: {
            vm: Object,
            _modifiers: Array
        }
    });

}


