import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return [
      'video'
    ];
  }

  initialize() {
  }

  toggle(e) {
    if(this.videoTarget.src) {
      this.videoTarget.removeAttribute('src');
    }
    else {
      this.videoTarget.src = this.videoTarget.dataset.src;
    }
  }
};