export default () => {
    // Get all the elements you want to show on scroll
    const targets = document.querySelectorAll('.animate');

    const buildThresholdList = function(numSteps = 10){
        let thresholds = [];
        
        for (let i = 1.0; i <= numSteps; i++) {
            let ratio = i/numSteps;
            thresholds.push(ratio);
        }
        
        thresholds.push(0);
        return thresholds;
    };

    // Callback for IntersectionObserver
    const callback = function(entries) {
        entries.forEach((entry, index) => {
            // Each entry describes an intersection change for one observed target element:
            //   entry.boundingClientRect
            //   entry.intersectionRatio
            //   entry.intersectionRect
            //   entry.isIntersecting
            //   entry.rootBounds
            //   entry.target
            //   entry.time
            const element = entry.target;
            
            // Is the element in the viewport?
            if (entry.isIntersecting) {
                element.classList.add('animate-in-view');
                const animations = element.querySelectorAll('[data-animate]');
                const threshold = element.dataset.animateThreshold || 0.2;

                // If the element threshold is larger than the root (viewport) then the viewport cannot show enough of the element at once to trigger animation, so trigger immediately
                const isThresholdUnattainable = entry.rootBounds.height < (entry.boundingClientRect.height * threshold);
                
                if ((threshold <= entry.intersectionRatio) || isThresholdUnattainable) {
                    if(element.dataset.animate) {
                        runAnimation(element);
                    }
    
                    animations.forEach(animation => runAnimation(animation));

                    if(!('animateInfinite' in element.dataset)) {
                        observer.unobserve(element);
                    }
                }                
            }
            else {
                element.classList.remove('animate-in-view');
            }
        });
    };

    const addAnimationClass = (element) => {
        const type = element.dataset.animate;
        let className = '';

        if(type === 'fade-up') {
            className = 'animate-fade-in-up'    
        }
        else if (type === 'fade') {
            className = 'animate-fade-in'
        }
        else {
            className = 'animate-animate';
        }

        // Add the animation class:
        element.classList.add(className);

    };

    const runAnimation = (animation) => {
        animation.style.setProperty('--animate-play-state', 'running');

    };

    // Set up a new observer
    const observer = new IntersectionObserver(callback, {
        root: null,
        rootMargin: '0px',
        threshold: buildThresholdList()
    });

    // Loop through each of the target
    targets.forEach(function(target) {

        // Add the element to the watcher
        observer.observe(target);

        // Add class to self/animated children
        if(target.dataset.animate) {
            addAnimationClass(target);
        }
        const animatedElements = target.querySelectorAll('[data-animate]');
        animatedElements.forEach(animatedElement => addAnimationClass(animatedElement));
    });
};