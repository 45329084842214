import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'svg'
    ];
  }

  initialize() {
    this.paths = this.svgTarget.querySelectorAll('path');    
    this.firstPath = this.paths[0];
    this.lastPath = this.paths[this.paths.length - 1];
    
    if(window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
      this.checkInView();
      this.svgTarget.classList.add('animation-grow');
      this.watchGrowAnimation();

      document.addEventListener('visibilitychange', () => {
        this.svgTarget.classList.toggle('is-tab-defocused', document.hidden);
      });
    }
  }

  watchGrowAnimation() {
    this.lastPath.addEventListener('animationend', (e) => {
      this.svgTarget.classList.add('animation-shrink');
      this.svgTarget.classList.remove('animation-grow');
      this.watchShrinkAnimation();
    }, {once: true});
  }

  watchShrinkAnimation() {
    this.lastPath.addEventListener('animationend', (e) => {
      this.svgTarget.classList.add('animation-grow');
      this.svgTarget.classList.remove('animation-shrink');
      this.watchGrowAnimation();
    }, {once: true});
  }

  checkInView() {
    const callback = (entries) => {
      entries.forEach((entry, index) => {
        this.svgTarget.classList.toggle('is-in-view', entry.isIntersecting);
      });
    };

    // Set up a new observer
    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: '0px',
      threshold: 0.01
    });

    observer.observe(this.element);
  }
};