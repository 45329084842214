import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'wrapper',
      'track'
    ];
  }

  initialize() {
    this.setupMediaQuery();
    window.addEventListener('scroll', () => {
      this.fixContent();
      requestAnimationFrame(() => this.move());
    }, {passive: true});
    window.addEventListener('resize', () => {
      requestAnimationFrame(() => this.handleResize());
    }, {passive: true});

    this.handleResize();
    this.fixContent();
    this.move();
  }

  fixContent() {
    this.element.classList.toggle('is-fixed', this.isInViewport(this.element));
  }

  // https://easings.net/en#easeInOutQuart
  easeInOutQuart(x) {
    return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
  }

  move() {
    const rect = this.element.getBoundingClientRect();
    let percent = ((rect.top * -1) / (rect.height - (window.innerHeight)));
    percent = Math.min(Math.max(percent, 0), 1);
    if(window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
      percent = this.easeInOutQuart(percent);
    }
    percent = percent * 100;
    percent = Number(percent.toFixed(2));
    this.element.classList.toggle('is-closer-to-top', percent < 50);
    this.element.classList.toggle('is-closer-to-bottom', percent >= 50);
    this.element.style.setProperty('--scroll-percent', percent);
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      (rect.bottom + window.pageYOffset) >= (window.pageYOffset + window.innerHeight) &&
      rect.top < 0
    );
  }
  
  setupMediaQuery() {
    const styles = getComputedStyle(this.element);
    const breakpoints = styles.getPropertyValue('--media-query');
    const mediaQuery = window.matchMedia(String(breakpoints));
    
    try {
      // Modern browsers
      mediaQuery.addEventListener('change', (e) => this.handleBreakpointChange(e));
    } 
    catch (e1) {
      try {
        // Old browsers (deprecated)
        mediaQuery.addListener('change', (e) => this.handleBreakpointChange(e));
      } catch (e2) {
        console.error(e2);
      }
    }
    
    // Initial check
    this.handleBreakpointChange(mediaQuery);
  }
  
  handleBreakpointChange(e) {
    this.element.classList.toggle('is-scrollable', e.matches)
  }

  handleResize() {
    let trackWidth = this.trackTarget.offsetWidth;
    this.element.style.setProperty('--track-width', trackWidth);    
    this.move();
  }
};