import template from './parAvailablePropertiesSlider.html';

export default (Vue, blockHelper) => {
    
    Vue.component('available-properties-slider', {
        mixins: [blockHelper.compile(template)],
        props: {
            vm: Object,
            _modifers: Array
        }
    });

    if (document.getElementsByClassName('available-properties-slider').length > 0) {
        
        document.querySelectorAll('[id^=available-properties-slider-]').forEach((element, index) => {
            const slider = element.querySelector('.available-properties-slider');
            new Vue({
                el: slider,
                data: function() {
                    return {
                        vm: { 
                            properties: []
                        }
                    }
                },
                async mounted() {
                    blockHelper.setup(this);
                }
            })
        });
    }

};