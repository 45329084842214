export default {
    data: function() {
        return {
            isFilteredOnDevelopment: false,
            backupProperties: [],
        };
    },
    methods: {
        clearDevelopment: function() {
            if(this.isFilteredOnDevelopment) {
                this.vm.properties = this.backupProperties;
                this.vm.recordCount = this.vm.properties.length;
                this.isFilteredOnDevelopment = false;
                this.showLetAgreedToggle = true;
                this.$refs.map.removeClearSelectionButton();
            }
        },
        selectDevelopment: function(developmentSlug) {

            this.backupAllProperties();
            var applyFilter = this.setSelectedStepOnMobile(developmentSlug);
         
            if(!this.isShowLetAgreed) this.showLetAgreedToggle = false;

            if(applyFilter) {
                this.vm.properties = [];
                setTimeout(() => {
                    this.vm.properties = this.backupProperties.filter((property) => { return property.developmentSlug == developmentSlug; });
                    this.vm.recordCount = this.vm.properties.length;
                    this.addMapClearSelectionButton();
                }, 500);
            }
        },
        backupAllProperties: function() {
            if(!this.isFilteredOnDevelopment) {
                this.backupProperties = this.vm.properties;
                this.isFilteredOnDevelopment = true;
            }
        },
        setSelectedStepOnMobile: function(development) {
            if(this.isMobile()) {
                if(this.selectedDevelopment == development) {
                    this.closeForMobile();
                    return false;
                }
                else {
                    this.selectedDevelopment = development;
                }
            }
            return true;
        }
    }
}