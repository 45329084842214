import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    const styles = getComputedStyle(this.element);
    const breakpoints = {
      oneItem: styles.getPropertyValue('--one-item-breakpoint'),
      twoItem: styles.getPropertyValue('--two-item-breakpoint'),
      threeItem: styles.getPropertyValue('--three-item-breakpoint'),
    };
    const gap = styles.getPropertyValue('--gap');
    const padding = styles.getPropertyValue('--slider-padding');

    this.slider = new Splide(this.element, {
      gap: gap,
      padding: padding,
      perPage: 4,
      pagination : false,
      arrows : true,
      autoWidth: false,
      breakpoints: {
        [breakpoints.oneItem]: {
          perPage: 1,
          autoWidth: true,
        },
        [breakpoints.twoItem]: {
          perPage: 2,
          autoWidth: false,
        },
        [breakpoints.threeItem]: {
          perPage: 3,
          autoWidth: false,
        },
      }
    } ).mount();

    document.dispatchEvent(new CustomEvent('NewSplideInstance', {detail: {'splideElement': this.element}}));
  }
};