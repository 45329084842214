import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    this.slider = new Splide(this.element, {
      gap: '2rem',
      perPage: 1,
      pagination : false,
      arrows : true
    } ).mount();

    document.dispatchEvent(new CustomEvent('NewSplideInstance', {detail: {'splideElement': this.element}}));
  }
};