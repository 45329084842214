export default {

    setHistoryPushStateFromState: function(state, title) {
    
        let url = `/search/${ state.isCommercial ? 'commercial' : 'residential' }/`;

        if(state.map) url = url +'map/';
        if(state.hideLetAgreed) url = url +'hide-let/';
    
        Object.keys(state.filters).forEach((key) => {

            var rawValue = state.filters[key];
            if(Array.isArray(rawValue)) {
                var value = rawValue.join(',');
                if(value && value != 'all')
                url = `${url}${key}_${value}/`;
            }
            else {
                url = `${url}${key}_${rawValue}/`;
            }
        });
    
        history.pushState(state, '', url);
        this.updateCanonicalTags(url);
        this.setPageTitle(state, title);
    },

    updateCanonicalTags: function(url) {
        url = `${window.location.protocol}//${window.location.hostname}${url}`;
        const canonical = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) { canonical.href = url; }
        const alternate = document.querySelector('link[rel="alternate"]');
        if (alternate !== null) { alternate.href = url; }
    },

    setPageTitle: function(state, title) {

        var output = state.isCommercial ? "Commercial " : "Residential ";
        output = output + `${title} `;

        if(Object.keys(state.filters).length > 0)
        {
            var filterApplied = false;
            Object.keys(state.filters).forEach((key) => {
                var vOutput = '';
                var rawValue = state.filters[key];

                if(Array.isArray(rawValue)) {
                    var value = rawValue.join(', ');
                    if(value && value != 'all') {
                        vOutput = vOutput + value.replace(/-/g, " ");
                    }
                }
                else {
                    vOutput = vOutput + rawValue.replace(/-/g, " ");
                }

                if(vOutput) {
                    if(filterApplied) output = output + 'and ';
                    output = output + key.replace(/-/g, " ") + ' ('+ vOutput +') ';
                    filterApplied = true;
                }
            })
        }
        
        document.title = output;

    }
}