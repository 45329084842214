import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {

  static get targets() {
    return [
      'text',
      'gallery',
      'caption'
    ];
  }

  initialize() {
    // const galleryBreakpoint = this.galleryTarget.dataset.breakpoint;

    this.secondarySlider = new Splide(this.textTarget, {
      arrows: false,
      pagination  : false,
      type       : 'fade',
      drag: false,
      keyboard: false,
      speed: 1200,
      waitForTransition: false,
    }).mount();
    
    this.primarySlider = new Splide(this.galleryTarget, {
      arrows: true,
      pagination  : false,
      type       : 'slide',
      waitForTransition: false,      
      speed: 1200,
      // breakpoints: {
      //   [galleryBreakpoint]: {
      //     heightRatio: 0.66667, // 3:2
      //   }
      // }
    });
    
    this.primarySlider.sync(this.secondarySlider).mount();
    
    this.captionTargets.forEach((element, index) => {
      element.querySelector('[data-slide-index]').innerHTML = index + 1;
      element.querySelector('[data-slide-count]').innerHTML = this.captionTargets.length;
    });

    document.dispatchEvent(new CustomEvent('NewSplideInstance', {detail: {'splideElement': this.element}}));
  }
};