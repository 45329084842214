import template from './parRelatedProperties.html';

export default (Vue, blockHelper) => {
    
    Vue.component('related-properties', {
        mixins: [blockHelper.compile(template)],
        props: {
            vm: Object,
            _modifers: Array
        }
    });

    if (document.getElementsByClassName('related-properties').length > 0) {
        new Vue({
            el: '.related-properties',
            data: function() {
                return {
                    vm: { 
                        properties: []
                    }
                }
            },
            async mounted() {
                blockHelper.setup(this);
            }
        })
    }

};