import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'svg'
    ];
  }

  initialize() {
    this.paths = this.svgTarget.querySelectorAll('path');
    this.animatingPathCount = 10;
    this.staggerDurationSeconds = 0.5;

    if(window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
      this.checkInView();
      this.addInitialClasses();

      this.svgTarget.addEventListener('animationend', (e) => {
        const path = e.target;
        path.classList.remove('is-animating');
        path.style.animationDelay = null;
        this.paths[this.getRandomIndex()].classList.add('is-animating')
      });

      document.addEventListener('visibilitychange', () => {
        this.svgTarget.classList.toggle('is-tab-defocused', document.hidden);
      });
    }
  }

  checkInView() {
    const callback = (entries) => {
      entries.forEach((entry, index) => {
        if(entry.isIntersecting) {
          this.svgTarget.classList.add('is-in-view');
        }
        else {
          this.svgTarget.classList.remove('is-in-view');          
        }
      });
    };

    // Set up a new observer
    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: '0px',
      threshold: 0.01
    });

    observer.observe(this.element);
  }

  addInitialClasses() {
    for(let index = 0; index < this.animatingPathCount; index++) {
      const path = this.paths[this.getRandomIndex()];
      path.classList.add('is-animating');
      path.style.animationDelay = (-1 * (index * this.staggerDurationSeconds)) + 's';
    }
  }

  getRandomIndex() {
    return Math.floor(Math.random() * (this.paths.length - 1));
  }
};