import template from './parDevelopmentSummary.html';

export default (Vue, blockHelper) => {

    Vue.component('development-summary', {
        mixins: [blockHelper.compile(template)],
        methods: {
            getPictSource : function(src, width, height, fileSize, isWebP, add2x) {
                var type = isWebP ? '&format=webp' : '';
                var output =  `${src}?quality=85&width=${width}&height=${height}&mode=crop&v=${fileSize}${type}`;
                if(add2x)
                    output = output + `, ${src}?quality=50&width=${width * 2}&height=${height * 2}&mode=crop&v=${fileSize}${type} 1.5x`;
                return output;
            }
        },
        props: {
            vm: Object,
            _modifers: Array
        }
    });
}