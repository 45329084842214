import createHTMLMapMarker from "../elements/customTool.js";

export default {
    data: function() {
      return {
          clearSelectionAdded: false
      }
    },
    methods: {
      addClearSelectionButton: function () {

        if(!this.clearSelectionAdded) {
          const clearSelectionDiv = document.createElement("div");
          createHTMLMapMarker(clearSelectionDiv, this.map.instance, this.clearDevelopment);
          this.map.instance.controls[google.maps.ControlPosition.TOP_CENTER].push(clearSelectionDiv);
          this.clearSelectionAdded = true;
        }

      },
      removeClearSelectionButton: function() {
        if(this.map.instance) {
          this.map.instance.controls[google.maps.ControlPosition.TOP_CENTER].clear();
          this.unSelectAllMarkers(true);
          this.clearSelectionAdded = false;
        }
      }
    },
    props: {
      clearDevelopment: Function
    }
  }