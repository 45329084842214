const run = () => {
    requestAnimationFrame(()=>{
        setVh();
    });
    window.addEventListener('resize', () => {
        setVh();
    });
};
const setVh = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    let vw = document.scrollingElement.clientWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
};
export default {
    run: run
};