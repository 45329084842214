import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    this.paths = this.element.querySelectorAll('path');
    this.lastPath = this.paths[this.paths.length - 1];

    window.addEventListener('scroll', () => {
      if(this.isInViewport(this.element)) {
        requestAnimationFrame(() => this.move());
      }
      else if(this.element.classList.contains('is-animating')) {
        this.element.classList.remove('is-animating');
      }
    }, {passive: true});

    this.lastPath.addEventListener('animationend', (e) => {
      this.element.classList.remove('is-animating');
    });
  }

  move() {
    let percent = ((window.innerHeight - this.element.getBoundingClientRect().top) / window.innerHeight) * 100;
    
    if(percent >= 50 && !this.element.classList.contains('is-animating')) {
      this.element.classList.add('is-animating');
    }
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0  &&
      rect.bottom <= (window.innerHeight + rect.height || document.documentElement.clientHeight + rect.height) 
    );
  }
};