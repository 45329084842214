import filtersMixin from 'libraryScripts/listings/mixins/listingFiltersMixin';
import propertiesMixin from './mixins/propertyListingPropertiesMixin';
import developmentMixin from './mixins/propertyListingDevelopmentMixin';
import letAgreeMixin from './mixins/propertyListingLetAgreedMixin';
import mapMixin from 'libraryScripts/listings/mixins/listingMapMixin';

export default (Vue, blockHelper) => {
    
    if (document.getElementsByClassName('property-listing-results').length > 0) {
        new Vue({
            mixins: [filtersMixin, propertiesMixin, developmentMixin, mapMixin, letAgreeMixin],
            el: '.property-listing-results',
            data: function() {
                return {
                    vm: {
                        properties: [],
                        letAgreedToggle: {},
                        mapToggle: {},
                        siteHeaderElement: undefined,
                    }
                }
            },
            methods: {
                isMobile: function() {
                    const breakpoint = getComputedStyle(this.$el).getPropertyValue('--mobile-width');
                    return (!window.matchMedia(`(min-width: ${breakpoint})`).matches);
                }
            },
            async mounted() {
                this.siteHeaderElement = document.querySelector('[data-controller="site-header"]');
                if(this.siteHeaderElement) {
                    this.siteHeaderElement.classList.add('is-property-listing');
                }
                this.initFilters();
                this.initMap();
                this.initLetAgreed();
                this.initData(blockHelper);
            }
        })
    }

};


