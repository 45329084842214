export default {
    data: function() {
        return {
            hideLetAgreedInline: false,
            showLetAgreedToggle: true
        };
    },
    computed: {
        isShowLetAgreed() {
            return this.vm.letAgreedToggle.isSelected;
        }
    },
    methods: {
        initLetAgreed: function() {
            let params = window.location.pathname.split('/');
            if(params.includes('hide-let')) {
                this.state.hideLetAgreed = true;
            }
        },
        isLetAgreedVisible: function(property) {
            if(!this.hideLetAgreedInline) {
                return true;
            }
            else {
                return !property.isLetAgreed;
            }
        },
        handleShowLetAgreed: function(vm, value) {

            var hideLetAgreed = !value ? true : false;

            if(hideLetAgreed) {
                this.vm.letAgreedToggle.isSelected = false;
                this.state.hideLetAgreed = true;
            }
            else {
                this.vm.letAgreedToggle.isSelected = true;
                this.state.hideLetAgreed = false;
            }

            if(this.isFilteredOnDevelopment) {

                this.hideLetAgreedInline = hideLetAgreed;
                this.updateHistoryPushState();
            }
            else {
                this.update(true);
            }
        }
    }
}