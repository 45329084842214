import template from './parDevelopmentList.html';

var mixin = {
    methods: {
        handleSelectOpen: function(isOpen) {
            this.isSelectOpen = isOpen;
        },
        handleTypeChange: function(name, selectedValues, selectedElements) {
            var propertyType = selectedElements[0].label;
            var type = this.vm.types.find((type) => { 
                return type.title === propertyType;
            })
            this.properties = type.developments;
            this.vm.map.markers = type.markers;
            this.$refs.map.loadMarkers();
        },
        getPictSource : function(src, width, height, fileSize, isWebP, add2x) {
            var type = isWebP ? '&format=webp' : '';
            var output =  `${src}?quality=85&width=${width}&height=${height}&mode=crop&v=${fileSize}${type}`;
            if(add2x)
                output = output + `, ${src}?quality=50&width=${width * 2}&height=${height * 2}&mode=crop&v=${fileSize}${type} 1.5x`;
            return output;
        }
    }
}

export default (Vue, blockHelper) => {
    
    Vue.component('development-list', {
        mixins: [blockHelper.compile(template), mixin],
        props: {
            vm: Object,
            _modifers: Array
        }
    });

    if (document.getElementsByClassName('development-list').length > 0) {
        new Vue({
            el: '.development-list',
            mixins: [mixin],
            data: function() {
                return {
                    vm: { },
                    properties: [],
                    isSelectOpen: false
                }
            },
            async mounted() {
                await blockHelper.setup(this);
                this.properties = this.vm.types[0].developments;
            }
        })
    }

};