import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'mapGalleryWrapper',
    ];
  }

  toggle(e) {
    document.body.classList.toggle('is-map-open');
    this.mapGalleryWrapperTarget.classList.toggle('is-map-open');
    document.querySelector('[data-controller="site-header"]').dispatchEvent(new Event('SubNavToggled'));
  }
};