import axios from 'axios';
import repoHelper from 'libraryScripts/listings/services/listingRepoHelper';


const getQueryConstants = (state, pageSize) => {

    let output = `?commercial=${state.isCommercial}`;
    output = `${output}&map=${state.map}`;
    output = `${output}&hideLetAgreed=${state.hideLetAgreed}`;
    output = `${output}&pageNo=${state.page.no}`;
    output = `${output}&pageSize=${pageSize}`;
    output = `${output}&orderBy=${state.orderBy}`;

    return output;

};


export default {

    apiLive : {
        search: function(state, pageSize, isInit) {
            const query = isInit ? repoHelper.getQueryFromUrl(state, pageSize, getQueryConstants) : repoHelper.getQueryFromState(state, pageSize, getQueryConstants);
            return axios.get('/umbraco/api/propertylisting/search'+ query);
        }
    },
    
    apiDev : {
        search: function(state, pageSize, isInit) {
            if(isInit) {
                return axios.get('/api/getResolved/parPropertyListingResults');
            }
            else {
                return axios.get('/api/getResolved/parPropertyListingResults_updated');
            }
        }
    }

}