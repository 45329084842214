import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    this.slider = new Splide(this.element, {      
      destroy: true,      
      breakpoints: {
        1365: {
          gap: '2rem',
          pagination : false,
          arrows : true,
          direction  : 'ltr',
          perPage: 1,
        },
      }
    } ).mount();

    document.dispatchEvent(new CustomEvent('NewSplideInstance', {detail: {'splideElement': this.element}}));
  }
};