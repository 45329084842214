import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {

  static get targets() {
    return [
      'rte',
      'gallery'
    ];
  }

  initialize() {
    const styles = getComputedStyle(this.galleryTarget);
    const gap = styles.getPropertyValue('--gap');
    const padding = styles.getPropertyValue('--slider-padding');
    const breakpoint = styles.getPropertyValue('--slider-breakpoint');

    this.slider = new Splide(this.galleryTarget, {
      gap: gap,
      padding: padding,
      perPage: 1,
      pagination : false,
      arrows : true,
      autoWidth: true,
      speed: 1000,
      waitForTransition: false, 
    } ).mount();

    document.dispatchEvent(new CustomEvent('NewSplideInstance', {detail: {'splideElement': this.galleryTarget}}));

    const mediaQuery = window.matchMedia(String(breakpoint));
    this.rteSlideAdded = false;

    if(this.hasRteTarget) {
      // Register event listener
      try {
        // Modern browsers
        mediaQuery.addEventListener('change', (e) => this.handleBreakpointChange(e));
      } 
      catch (e1) {
        try {
          // Old browsers (deprecated)
          mediaQuery.addListener('change', (e) => this.handleBreakpointChange(e));
        } catch (e2) {
          console.error(e2);
        }
      }      
  
      // Initial check
      this.handleBreakpointChange(mediaQuery);
    }
  }

  handleBreakpointChange(e) {
    if(e.matches && !this.rteSlideAdded) {
      this.slider.add(`<li class="${this.rteTarget.dataset.classes}">${this.rteTarget.innerHTML}</li>`, 0);
      this.rteSlideAdded = true;
    }
    else if(!e.matches && this.rteSlideAdded) {
      this.rteSlideAdded = false;
      this.slider.remove(0);
    }
    this.slider.go(0);
  }
};