import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    this.paths = this.element.querySelectorAll('path');

    this.element.addEventListener('transitionend', (e) => {
      e.target.classList.remove('is-animating');
    });

    this.element.addEventListener('mouseover', (e) => {
      if(e.target.tagName.toUpperCase() === 'POLYGON') {
        e.target.classList.add('is-animating');
      }
    });
  }
};