import Vue from 'vue';

const assign = (component, data) => {
    Object.keys(data).forEach((key) => {
        component.vm[key] = data[key];
    });
};

export default {
    setup: async (component) => {
    
        if(component.$el.dataset) {
    
            let json = '';
        
            if(component.$el.dataset.context)
            {
                json = JSON.parse(component.$el.dataset.context);
            }
            else if (component.$el.dataset.endpoint) {
                var url = component.$el.dataset.endpoint;
                const response = await fetch(url);
                json = await response.json();
            }
    
            assign(component, json); 
        }
    },
    compile: (template) => {
        const compiledTemplate = Vue.compile(template);

        return {
            render(createElement) {
                return compiledTemplate.render.call(this, createElement);
            }
        };
    },
    assign: assign
};
