import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'counter'
    ];
  }

  initialize() {
    this.start = parseInt(this.element.dataset.start);
    this.end = parseInt(this.element.dataset.end);

    if(window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
      this.counterTarget.innerHTML = this.start;
      this.setup();
    }
  }

  setup() {
    const callback = (entries) => {
      entries.forEach((entry, index) => {
        if(entry.isIntersecting) {
          this.animateValue(this.counterTarget, this.start, this.end, 2000);
          observer.unobserve(entry.target);
        }
      });
    };

    // Set up a new observer
    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: '0px',
      threshold: 1
    });

    observer.observe(this.element);
  }

  animateValue(element, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if(!startTimestamp) {
        startTimestamp = timestamp;
      }
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      element.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }
};