import template from "./parFormCheckboxRadio.html";

export default (Vue, blockHelper) => {

  Vue.component("form-checkbox-radio", {
    mixins: [blockHelper.compile(template)],
    data: function() {
      return {
        currentArr: [],
        current: "",
      };
    },
    computed: {
      model: {
        get: function () {
          if (this.selectedArr) {
            if (this.isCheckbox) {
              return this.selectedArr;
            } else {
              return this.selectedArr[0];
            }
          } else {
            if(this.vm.isSelected && !this.selected) {
              return this.vm.value;
            }
            return this.selected;
          }
        },
        set: function (newValue) {
          if (this.isCheckbox) {
            this.currentArr = newValue;
          } else {
            this.currentArr = [];
            this.currentArr.push(newValue);
          }
        },
      },
      isCheckbox() {
        return this.vm.type === "checkbox";
      },
    },
    methods: {
      onChange: function () {
        this.$emit("select-value", this.vm, this.currentArr);
      },
    },
    props: {
      vm: Object,
      _modifiers: Array,
      selectedArr: Array,
      selected: String,
    },
  });
};
