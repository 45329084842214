import template from './parFormPropertyListFilter.html'
import propertyListingMixin from './mixins/propertyListingFilterMixin';

export default (Vue, blockHelper) => {

    Vue.component('form-property-list-filter', {
        mixins: [blockHelper.compile(template), propertyListingMixin],
        data: function() {
            return {
                lastSelectedElement: undefined,
                selectedValues: this.vm.defaultValues
            }
        },
        computed: {
            hasSelected() {
                return this.selectedValues.length > 0;
            },
            selectedCount() {
                return this.selectedValues.length;
            },
            selectedLabel() {
                if(!this.isCheckboxList) {
                    var el = this.vm.elements
                        .find(x => this.selectedValues.includes(x.value)) 
                    return el ? el.label : '';
                }
                else if(!this.hasSelected) {
                    return 'None';
                }
                else if(this.hasSelected && this.vm.elements[0].value == this.vm.elements[0]) {
                    return this.vm.elements[0].label;
                }
                else if(this.selectedCount == 1) {
                    return this.vm.elements
                        .find(x => this.selectedValues[0] === x.value).label;
                }
                else {
                    return 'Multiple Applied';
                }
            },
            isCheckboxList() {
                return this.vm.elements.length > 0 && this.vm.elements[0].type === 'checkbox';
            }
        },
        methods: {
            selectValue: function(element, newSelectedValues) 
            {
                if(newSelectedValues) {
                    this.lastSelectedElement = element;
                    this.selectedValues = newSelectedValues;
                    this.onChange();
                }
            }
        },
        props: {
            vm: Object,
            _modifiers: Array
        }
    });

}


