import focus from "focus-visible";
import vh from "./common/vh";

import aria from './aria/aria';
import smoothscroll from 'smoothscroll-polyfill';

import animate from './animation/animateIn';

import { Application } from "stimulus";
import utils from "./common/utils";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import splideArrows from './website/splide_arrows';

vh.run();
smoothscroll.polyfill();
const application = Application.start();
utils.setup(application);

const context = require.context('./website', true, /\.js$/);

application.load(definitionsFromContext(context));


import Vue from 'vue';
import blockHelper from './common/blockHelper';

import developmentList from 'vueFiles/_content/developmentList/parDevelopmentList.js';

import propertyListingResults from 'vueFiles/propertyListing/propertyListingResults/parPropertyListingResults.js';
import developmentListingResults from 'vueFiles/propertyListing/developmentListingResults/parDevelopmentListingResults.js';
import availablePropertiesSlider from 'vueFiles/global/availablePropertiesSlider/parAvailablePropertiesSlider.js';
import relatedProperties from 'vueFiles/global/relatedProperties/parRelatedProperties.js';

import propertySummary from 'vueFiles/global/propertySummary/parPropertySummary.js';
import developmentSummary from 'vueFiles/global/developmentSummary/parDevelopmentSummary.js';
import map from 'vueFiles/global/map/parMap.js';

import formButton from 'vueForms/formButton/parFormButton.js';
import formSelect from 'vueForms/formSelect/parFormSelect.js';
import formCheckboxRadioList from 'vueForms/formCheckboxRadioList/parFormCheckboxRadioList.js';
import formPropertyListFilter from 'vueForms/formPropertyListFilter/parFormPropertyListFilter.js';
import formCheckboxRadio from 'vueForms/formCheckboxRadio/parFormCheckboxRadio.js';


window.onload = function () {
    // Vue
    propertySummary(Vue, blockHelper);
    developmentSummary(Vue, blockHelper);
    formButton(Vue, blockHelper);
    formSelect(Vue, blockHelper);
    formCheckboxRadioList(Vue, blockHelper);
    formPropertyListFilter(Vue, blockHelper);
    formCheckboxRadio(Vue, blockHelper);
    availablePropertiesSlider(Vue, blockHelper);
    relatedProperties(Vue, blockHelper);
    map(Vue, blockHelper);
    propertyListingResults(Vue, blockHelper);
    developmentListingResults(Vue, blockHelper);
    developmentList(Vue, blockHelper);
    
    // Misc.
    splideArrows();    
    animate();
}