import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  toggle(e) {
    this.element.classList.toggle('is-enquire-open');
    this.element.scrollIntoView(true);
  }
};