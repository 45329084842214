import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'form',
      'response',
    ];
  }

  initialize() {
  }

  toggleLoadedState(e) {
    this.formTarget.innerHTML = e.detail.oldContent;
    this.responseTarget.innerHTML = e.detail.newContent;
    this.element.classList.add('loaded');
  }
};