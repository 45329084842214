import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return [
      'title'
    ];
  }

  initialize() {
    if(this.element) {
      this.uId = this.generateRandomId();
      this.titleSuffix = '-label';
      this.titleId = this.uId + this.titleSuffix;
  
      this.attrs = {
        'role': 'modal',
        'id': this.uId,
        'aria-labelledby': this.hasTitleTarget ? this.titleId : '',
        'aria-modal': 'true'
      };    
  
      this.addAttributes(this.element, this.attrs);
      if(this.hasTitleTarget) {
        this.addAttributes(this.titleTarget, {'id': this.titleId});
      }

      this.checkAutomaticallyOpen();
    }
  }
  
  checkAutomaticallyOpen() {const queryString = window.location.search;
    const modalId = this.element.id;
    const params = new URLSearchParams(queryString);

    if(params.has("openModal") && params.get("openModal") === modalId) {
      const firstFocusable = document.querySelectorAll('button, a[href], input, select, textarea, [tabindex]:not([tabindex="-1"])')[0];
      window.openDialog(this.element.id, firstFocusable);
    }
  }

  open(e) {
    const triggerElement = e.currentTarget;

    const id = triggerElement.dataset.modalId;
    const focusAfter = triggerElement.dataset.focusAfter ? triggerElement.dataset.focusAfter : triggerElement;
    const focusNext = triggerElement.dataset.focusNext ? triggerElement.dataset.focusNext : undefined;
    const isScrollLocked = triggerElement.dataset.lockScroll === 'true';
    this.element.classList.toggle('is-open');
    window.openDialog(id, focusAfter, focusNext, isScrollLocked);
  }

  close(e) {    
    const triggerElement = e.currentTarget;
    this.element.classList.toggle('is-open');
    window.closeDialog(triggerElement);
  }

  replace(e) {
    const triggerElement = e.currentTarget;

    const id = triggerElement.dataset.modalId;
    const focusAfter = triggerElement.dataset.focusAfter ? triggerElement.dataset.focusAfter : triggerElement;
    const focusNext = triggerElement.dataset.focusNext ? triggerElement.dataset.focusNext : undefined;
    window.replaceDialog(id, focusAfter, focusNext);
  }

  generateRandomId() {
    return '_' + (
      Number(
        String(Math.random()).slice(2)) + 
        Date.now() + 
        Math.round(performance.now())
      ).toString(36);
  }

  addAttributes(element, obj) {
    for(const key in obj) {
      const value = obj[key];

      if(!element.hasAttribute(key) && value) {
        element.setAttribute(key, value);
      }
    }
  }
};