import { Controller } from "stimulus";
import app from '../common/utils';

export default class extends Controller {

  static get targets() {
    return [
      'link',
      'toggleButton'
    ];
  }

  initialize() {
    this.breakpoint = getComputedStyle(this.element).getPropertyValue('--mobile-width');
    this.sectionTargets = [];

    this.getSections();

    window.addEventListener('scroll', () => {
      requestAnimationFrame(() => this.checkSectionsInView());
    }, {passive: true});
    window.addEventListener('resize', () => {
      requestAnimationFrame(() => this.checkSectionsInView());
    }, {passive: true});

    const observer = new IntersectionObserver(([e]) => {
        this.element.classList.toggle('is-sticky', e.intersectionRatio < 1)
      }, 
      {
        rootMargin: '-1px 0px 0px 0px',
        threshold: [1],
      }
    );
    
    observer.observe(this.element);
    this.checkSectionsInView()
  }

  getSections() {
    this.linkTargets.forEach(element => {
      let id = element.hash.substr(1);
      if(id) {
        this.sectionTargets.push(document.getElementById(id));
      }
    });
  }

  checkSectionsInView() {
    let styles = getComputedStyle(this.element);
    this.scrollMarginTop = parseInt(styles.getPropertyValue('scroll-margin-top'), 10);
    let sectionActive = false;

    this.sectionTargets.every(section => {
      if(this.isInViewport(section)) {
        let id = section.id;
        this.linkTargets.forEach(element => {
          sectionActive = true;
          element.classList.toggle('is-active', element.href.includes(`#${id}`));
        });
        return false;
      }
      return true;
    });
    if(!sectionActive) {
      this.linkTargets.forEach(element => {
        element.classList.remove('is-active');
      });
    }
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top - this.scrollMarginTop < window.innerHeight && rect.bottom > this.scrollMarginTop + 1
    );
  }

  toggle(e) {
    if(this.isMobile()) {
      const isOpen = this.toggleButtonTarget.getAttribute('aria-expanded') !== 'true';
      this.toggleButtonTarget.setAttribute('aria-expanded', isOpen);
      this.element.classList.toggle('is-open', isOpen);
      document.body.classList.toggle('is-page-nav-open', isOpen);
      document.querySelector('[data-controller="site-header"]').dispatchEvent(new Event('SubNavToggled'));
      this.element.scrollIntoView(true);
    }
  }

  isMobile() {
    return (!window.matchMedia(`(min-width: ${this.breakpoint})`).matches);
  }
}