import { Controller } from "stimulus";
import app from '../common/utils';

export default class extends Controller {

  static get targets() {
    return [
      'link',
      'toggleButton'
    ];
  }

  initialize() {
    app.setup(this);
    this.sections = document.querySelectorAll('[data-target="page-nav.section"]');
    const debouncedScroll = this.utilites.debounce(() => {
      this.onScroll();
    }, 25);
    // window.addEventListener('scroll', debouncedScroll);
    this.currentSection;
    this.onScroll();
  }

  onScroll() {
    const scrollMarginTop = parseInt(getComputedStyle(document.body).scrollMarginTop, 10) + 1;
    const currentScroll = window.pageYOffset;
    let newSection;
    for (let i = 0, len = this.sections.length; i < len; i++) {
      const element = this.sections[i];
      const offsetTop = element.offsetTop - scrollMarginTop;
      const offSetBottom = (element.offsetTop + element.offsetHeight) - scrollMarginTop;

      if (currentScroll >= offsetTop && currentScroll < offSetBottom) {
        newSection = element;
        break;
      }      
    }

    if(newSection && (this.currentSection !== newSection.id)) {
      this.linkTargets.forEach(link => {
        if(link.getAttribute("href") == '#' + newSection.id) {
          link.classList.add('is-active')
        }
        else {
          link.classList.remove('is-active')
        }
      });
      this.currentSection = newSection.id;
    }
  }

  isMobile() {
    const breakpoint = getComputedStyle(this.element).getPropertyValue('--mobile-width');
    return (!window.matchMedia(`(min-width: ${breakpoint})`).matches);
  }

  toggle(e) {
    if(this.isMobile()) {
      this.toggleButtonTarget.setAttribute('aria-expanded', this.toggleButtonTarget.getAttribute('aria-expanded') !== 'true');
      this.element.classList.toggle('is-open');
      document.body.classList.toggle('is-page-nav-open');
      document.querySelector('[data-controller="site-header"]').dispatchEvent(new Event('SubNavToggled'));
    }
  }
}