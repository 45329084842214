import repo from '../services/propertyListingRepo';
import routing from '../services/propertyListingRouting';

const pageTitleType = 'Properties';

export default {
    data: function() {
        return {
            isLoading: false,
            isDev: false,
            api: {},
            state: {
                isCommercial: window.location.pathname.split('/')[2] == 'commercial',
                page: {
                    no: 0,
                    size: 50,
                    sizeForMap: 100
                },
                orderBy: '',
                map: false,
                hideLetAgreed: false,
                filters: {}
            }
        }
    },
    computed: {
        pageSize: {
            get: function() {
                return this.state.map ? this.state.page.sizeForMap : this.state.page.size;
            }
        }
    },
    methods: {
        initData: function(blockHelper) {
            this.isDev = document.documentElement.dataset.frontendEnvironment === 'true';
            this.api = this.isDev ? repo.apiDev : repo.apiLive;

            this.api.search(this.state, this.pageSize, true).then((response) => {
                
                blockHelper.assign(this, response.data);
                
                this.state.orderBy = this.vm.sortField.defaultValue;
                this.vm.searchFields.forEach((e) => {
                    this.state.filters[e.name] = e.defaultValues;
                });
                if(!this.vm.letAgreedToggle.isSelected) {
                    this.state.filters.hideUnderOffer = true;
                }

                routing.setPageTitle(this.state, pageTitleType);

            });
        },
        update: function(isSearch) {

            if(isSearch) {
                //must clear property list for splide lazy loading to work!
                this.vm.properties = [];
                this.vm.relatedProperties = {};
                this.state.page.no = 0;
            }

            this.api.search(this.state, this.pageSize).then((response) => {

                this.clearDevelopment();

                this.vm.recordCount = response.data.recordCount;
                this.vm.searchFields = response.data.searchFields;

                if(isSearch) {
                    this.vm.properties = response.data.properties;
                    this.vm.relatedProperties = response.data.relatedProperties;
                    this.forceFilterResize();
                    this.updateMapMarkers(response.data.map.markers);
                    this.resetMap();
                }
                else {
                    this.vm.properties = [...this.vm.properties, ...response.data.properties];
                }
                this.isLoading = false;
                if(!this.isDev) {
                    routing.setHistoryPushStateFromState(this.state, pageTitleType);
                    this.vm.searchFields = response.data.searchFields;
                }
                if(!isSearch) {
                    window.scroll(window.scrollX, this.previousScrollPosition);
                }
            });
        },
        page: function() {
            this.isLoading = true;
            this.state.page.no ++;
            this.update(false);
            this.previousScrollPosition = window.scrollY;
        },
        sort: function(orderBy) {
            this.state.orderBy = orderBy;
            this.update(true);
        },
        clear: function() {

            var promises = [];
            this.$refs.filters.forEach(filter => {
                promises.push(filter.clear());                    
            });
            Promise.all(promises).then(() => 
                this.update(true)
            );
        },
        updateHistoryPushState: function() {
            if(!this.isDev) {
                routing.setHistoryPushStateFromState(this.state, );
            }
        }
    }
}