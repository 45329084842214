import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {

  static get targets() {
    return [
      'thumbnails',
      'gallery',
    ];
  }

  initialize() {
    const galleryBreakpoint = this.galleryTarget.dataset.breakpoint;

    this.secondarySlider = new Splide(this.thumbnailsTarget, {
      arrows: false,
      gap         : 12,
      isNavigation: true,
      pagination  : false,
      perPage     : 4,
      waitForTransition: false,
    }).mount();
    
    this.primarySlider = new Splide(this.galleryTarget, {
      type       : 'fade',      
      pagination : false,
      waitForTransition: false,
      breakpoints: {
        [galleryBreakpoint]: {
          heightRatio: 0.66667, // 3:2
        }
      }
    });
    
    this.primarySlider.sync(this.secondarySlider).mount();

    document.dispatchEvent(new CustomEvent('NewSplideInstance', {detail: {'splideElement': this.element}}));
  }
};