export default (controlDiv, map, onClick) => {
  const controlUI = document.createElement("button");
  controlUI.classList.add("map__clear");
  controlUI.title = "Click to clear the map selection";
  controlDiv.appendChild(controlUI);
  controlUI.innerHTML = `<span class="map__clear__label">Reset map</span>
                        <svg class="map__clear__icon" aria-hidden="true">
                          <use xlink:href="/spritemap.svg#reset"></use>
                        </svg>`;  
  controlUI.addEventListener("click", onClick);
};