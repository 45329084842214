import axios from 'axios';
import repoHelper from 'libraryScripts/listings/services/listingRepoHelper';


const getQueryConstants = (state, pageSize) => {

    return `?map=${state.map}`;

};

export default {

    apiLive : {
        search: function(state, pageSize, isInit) {
            const query = isInit ? repoHelper.getQueryFromUrl(state, pageSize, getQueryConstants) : repoHelper.getQueryFromState(state, pageSize, getQueryConstants);
            return axios.get('/umbraco/api/developmentlisting/search'+ query);
        }
    },
    
    apiDev : {
        search: function(state, pageSize, isInit) {
            if(isInit) {
                return axios.get('/api/getResolved/parDevelopmentListingResults');
            }
            else {
                return axios.get('/api/getResolved/parDevelopmentListingResults_updated');
            }
        }
    }

}