import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return [
      'enlargedImage'
    ];
  }

  initialize() {

  }

  toggle(e) {
    const triggerElement = e.currentTarget;
    const index = triggerElement.dataset.index;
    this.enlargedImageTargets[index].classList.toggle('is-open');
    this.element.classList.toggle('is-image-open');
  }
};