import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
        'secondaryContent',
        'navToggle',
        'navAccordion',
        'navAccordionToggle',
    ];
  }

  initialize() {
    this.hideClass = 'site-header-hide';
    this.showClass = 'site-header-show';
    window.addEventListener('scroll', () => this.onScroll(), {passive: true});
    document.body.classList.add('is-initial-scroll-position');
    this.onScroll();
  }

  onScroll() {
    const currentScroll = window.pageYOffset;
    
    if(currentScroll <= 0) {
      document.body.classList.add(this.showClass);
      document.body.classList.remove(this.hideClass);
      return;
    }
    if(currentScroll >= 150 && document.body.classList.contains('is-initial-scroll-position')) {
      document.body.classList.remove('is-initial-scroll-position');
    }
    
    if(currentScroll > this.lastScroll && !document.body.classList.contains(this.hideClass)) {
      // down
      this.slideUp();
    } 
    else if(currentScroll < this.lastScroll && document.body.classList.contains(this.hideClass)) {
      // up
      this.slideDown();
    }
    this.lastScroll = currentScroll;
  }

  toggle(e) {
    this.navToggleTargets.forEach(toggle => {
      toggle.setAttribute('aria-expanded', toggle.getAttribute('aria-expanded') !== 'true');
    });
    this.element.classList.toggle('is-open');
    
    if(this.element.dataset.static != undefined) {
      window.scrollTo(0,0);
    }
    
    if(e) {
      document.body.classList.toggle('is-site-nav-open');
    }
  }

  subNavToggle() {
    this.slideDown();
    this.element.classList.toggle('is-subnav-open');
  }

  slideDown() {
    document.body.classList.add(this.showClass);
    document.body.classList.remove(this.hideClass);
  }

  slideUp() {
    document.body.classList.add(this.hideClass);
    document.body.classList.remove(this.showClass);
  }
  
  toggleAccordion(e) {
    const toggle = e.currentTarget;
    const index = this.navAccordionTargets.findIndex(target => target.contains(toggle));
    const accordion = this.navAccordionTargets[index];

    toggle.setAttribute('aria-expanded', toggle.getAttribute('aria-expanded') !== 'true');
    accordion.classList.toggle('is-open');
  }
};