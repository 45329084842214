import template from './parFormSelect.html'

export default (Vue, blockHelper) => {

    Vue.component('form-select', {
        mixins: [blockHelper.compile(template)],
        data: function() {
            return {
              selectedValue: ''
            };
        },
        computed: {
            model: {
                get: function() {
                    if(!this.selectedValue)
                        return this.vm.defaultValue;
                    else
                        return this.selectedValue;
                },
                set: function(newValue) {
                    this.selectedValue = newValue;
                }
            }
        },
        methods: {
            onChange: function () {
              this.$emit("select-value", this.selectedValue);
            },
        },
        props: {
            vm: Object,
            _modifiers: Array
        }
    });

}


