export default {
    data: function() {
        return {
            isMapActive: false
        }
    },
    methods: {
        initMap: function() {
            let params = window.location.pathname.split('/');
            if(params.includes('map') && !this.isMobile()) {
                this.state.map = true;
                this.isMapActive = true;
                this.addMapBodyClass();
            }
        },
        closeForMobile: function() {
            if(this.isMobile()) {
                this.toggleMap();
            }
        },
        resetMap: function() {
            if(!this.state.map) this.isMapActive = false;
        },
        updateMapMarkers: function(markers) {
            if(this.$refs.map) {
                this.vm.map.markers = markers;
                this.$refs.map.loadMarkers();
            }
        },
        addMapClearSelectionButton: function() {
            if(this.$refs.map) {
                this.$refs.map.addClearSelectionButton();
            }
        },
        toggleMap: function() {
            this.state.map = !this.state.map;
            this.vm.mapToggle.isSelected = this.state.map;
            this.addMapBodyClass();
 
            if(!this.isMapActive) {
                this.update(true);
                this.isMapActive = true;
            }

            this.updateHistoryPushState();
        },
        addMapBodyClass: function()
        {
            document.body.classList.toggle('is-map-open');
            this.siteHeaderElement.dispatchEvent(new Event('SubNavToggled'));
        }
    }
}