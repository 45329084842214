import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    const interval = 6000;
    const speed = 2000;
    this.slider = new Splide(this.element, {
      type    : 'fade',
      autoplay: true,
      pauseOnHover: false,
      pauseOnFocus: false,
      drag: false,
      pagination : true,
      arrows : false,
      interval: interval,
      speed: speed,
    } ).mount();

    this.element.style.setProperty('--interval', interval);
    this.element.style.setProperty('--speed', speed);

    this.slider.on('moved', (newIndex, oldIndex, destIndex) => {
      if(newIndex === oldIndex) {
        this.slider.go(0); 
      }
    });
  }
};