export default {
    data: function() {
        return {
            isFilteredOnDevelopment: false,
            backupDevelopments: [],
        };
    },
    methods: {
        clearDevelopment: function() {
            if(this.isFilteredOnDevelopment) {
                this.vm.developments = this.backupDevelopments;
                this.isFilteredOnDevelopment = false;
                this.$refs.map.removeClearSelectionButton();
            }
        },
        selectDevelopment: function(developmentSlug) {

            this.backupAllDevelopments();
            var applyFilter = this.setSelectedStepOnMobile(developmentSlug);

            if(applyFilter) {
                this.vm.developments = [];
                setTimeout(() => {
                    this.vm.developments = this.backupDevelopments.filter((development) => { return development.developmentSlug == developmentSlug; });
                    this.addMapClearSelectionButton();
                }, 500);
            }
        },
        backupAllDevelopments: function() {
            if(!this.isFilteredOnDevelopment) {
                this.backupDevelopments = this.vm.developments;
                this.isFilteredOnDevelopment = true;
            }
        },
        setSelectedStepOnMobile: function(development) {
            if(this.isMobile()) {
                if(this.selectedDevelopment == development) {
                    this.closeForMobile();
                    return false;
                }
                else {
                    this.selectedDevelopment = development;
                }
            }
            return true;
        }
    }
}