import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'video',
      'customPoster',
    ];
  }

  initialize() {
    window.YT.ready(() => {
      this.videoElement = new YT.Player(this.videoTarget, {
        videoId: this.videoTarget.dataset.src,
        playerVars: { 'controls': 0, 'loop': 1, 'showinfo': 0, 'autohide': 1, 'autoplay': 1, 'mute': 1, 'playlist': this.videoTarget.dataset.src },
        events: {
          'onReady': (e) => this.ready(e),
          'onStateChange': (e) => this.stateChange(e)
        }
      });
    });
  }

  ready(e) {    
    this.videoElement.mute();
    this.videoElement.playVideo();
    this.stateChange();
  }
  
  stateChange(e) {
    const state = this.videoElement.getPlayerState();

    if (state === 1 || state === 3) {
      this.videoTarget.style.opacity = 1;
    }
    else {
      this.videoTarget.style.opacity = 0;
      if (this.hasCustomPosterTarget) {
        this.customPosterTarget.style.display = 'block';
      }
    }
  }
};