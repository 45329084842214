import { Controller } from "stimulus";

export default class extends Controller {

  initialize() {

    this.formElement = document.getElementById('position-applied-for');
    this.fieldWrapper = this.formElement.parentElement.parentElement;
    this.hideField();
  }

  setForm(e) {
    let vacancyTitle = e.currentTarget.dataset['vacancy'];

    this.formElement.value = vacancyTitle;
    this.showField();
  }

  clearForm() {
    this.formElement.value = '';
    this.hideField();
  }

  showField() {
    this.fieldWrapper.style.display = 'block';
  }

  hideField() {
    this.fieldWrapper.style.display = 'none';
  }
};