import app from '../common/utils';

const findSplideArrows = () => {
    let instances = document.querySelectorAll('.splide__arrows');

    instances.forEach(element => {
        checkArrows(element);
    });
}

const newSplideInstance = (e) => {
    checkArrows(e.detail.splideElement.querySelector('.splide__arrows'));
}

const checkArrows = (splideInstance) => {
    let classAction = splideInstance.querySelectorAll('.splide__arrow[disabled]').length > 1 ? 'add' : 'remove';
    splideInstance.classList[classAction]('is-arrows-disabled');
};

export default function() {
    let application = {};
    app.setup(application);
    document.addEventListener('NewSplideInstance', newSplideInstance, false);
    
    window.addEventListener('resize', application.utilites.debounce(() => {
        findSplideArrows();
    }, 250));

    findSplideArrows();
}