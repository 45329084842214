import template from "./parMap.html";
import mapMixin from "./mixins/mapMixin.js";
import markerMixin from "./mixins/markerMixin.js";
import customToolMixin from "./mixins/customToolMixin.js";


export default (Vue, blockHelper) => {


  Vue.component("google-map", {
    mixins: [blockHelper.compile(template), mapMixin, markerMixin, customToolMixin],
    props: {
      vm: Object,
      _modifers: Array
    }
  });

  if (document.getElementsByClassName('map').length > 0) {
    new Vue({
        el: '.map',
        data: function() {
          return {
              vm: {
                apiKey: null, 
                markers: []
              }
          }
        },
        mixins: [mapMixin, markerMixin, customToolMixin],
        async mounted() {
            await blockHelper.setup(this);
            this.initMap();
        }
    })
}

};
