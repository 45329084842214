export default {
    getQueryFromUrl: (state, pageSize, getQueryConstants) => {

        let params = window.location.pathname.split('/');
        let output = getQueryConstants(state, pageSize);
    
        let count = 0;
        params.forEach(field => {
    
            if(count > 2 && field) {
                let fieldItems = field.split('_');
                if(fieldItems.length > 1)
                    output = `${output}&${fieldItems[0]}=${fieldItems[1]}`;  
            }
            count ++;
        })
    
        return output;
    },
    getQueryFromState: (state, pageSize, getQueryConstants) => {
    
        let output = getQueryConstants(state, pageSize);
    
        Object.keys(state.filters).forEach((key) => {
            var rawValue = state.filters[key];
            if(Array.isArray(rawValue)) {
                var value = rawValue.join(',');
                if(value && value != 'all')
                    output = `${output}&${key}=${value}`;
            }
            else {
                output = `${output}&${key}=${rawValue}`;
            }
        });
    
        return output;
    }
}