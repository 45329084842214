import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    this.slider = new Splide(this.element, {
      type       : 'fade',
      lazyLoad: 'nearby',
      preloadPages: 0,
      heightRatio: 0.5952,
      pagination : false,
      waitForTransition: false,
    } ).mount();

    document.dispatchEvent(new CustomEvent('NewSplideInstance', {detail: {'splideElement': this.element}}));
  }
};