import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    this.percentModifier = 1.25;

    if(window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
      window.addEventListener('scroll', () => {
        if(this.isInViewport(this.element)) {
          requestAnimationFrame(() => this.move());
        }
      }, {passive: true});
      this.move();
    }
    else {
      this.element.style.setProperty('--percent', 100);
    }
  }

  move() {
    let percent = ((window.innerHeight - this.element.getBoundingClientRect().top) / window.innerHeight) * 100;
    percent = percent * this.percentModifier;
    percent = Math.min(Math.max(percent, 0), 100);
    this.element.style.setProperty('--percent', percent.toFixed(2));
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0  &&
      rect.bottom <= (window.innerHeight + rect.height || document.documentElement.clientHeight + rect.height) 
    );
  }
};