import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    // const galleryBreakpoint = this.galleryTarget.dataset.breakpoint;

    const styles = getComputedStyle(this.element);
    const breakpoints = {
      oneItem: styles.getPropertyValue('--one-item-breakpoint'),
      twoItem: styles.getPropertyValue('--two-item-breakpoint'),
      threeItem: styles.getPropertyValue('--three-item-breakpoint'),
      fourItem: styles.getPropertyValue('--four-item-breakpoint'),
    };
    const gap = styles.getPropertyValue('--gap');
    const padding = styles.getPropertyValue('--slider-padding');

    this.slider = new Splide(this.element, {
      gap: gap,
      padding: padding,
      perPage: 4,
      pagination : false,
      arrows : true,
      breakpoints: {
        [breakpoints.oneItem]: {
          perPage: 1
        },
        [breakpoints.twoItem]: {
          perPage: 2
        },
        [breakpoints.fourItem]: {
          perPage: 4
        },
      }
    } ).mount();

    document.dispatchEvent(new CustomEvent('NewSplideInstance', {detail: {'splideElement': this.element}}));
  }
};