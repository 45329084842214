import { Loader } from "@googlemaps/js-api-loader";
import styles from '../style/mapStyle.js';

var loader = null;
var initLoader = (apiKey) => {
    loader = new Loader({
        apiKey: apiKey,
        version: "weekly",
        libraries: ["places"],
    });
}

var getConfig = (hideControls) => {

    if (hideControls) {
        return {
            draggable: false,
            panControl: false,
            scrollwheel: false,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            restriction: {
                // Limit to UK view
                latLngBounds: {
                    north: 59.66219,
                    south: 49.70317,
                    west: -10.86548,
                    east: 2.01050,
                },
                strictBounds: true,
            },
            styles: styles,
        };
    }
    else {
        return {
            scrollwheel: false,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            restriction: {
                // Limit to UK view
                latLngBounds: {
                    north: 59.66219,
                    south: 49.70317,
                    west: -10.86548,
                    east: 2.01050,
                },
                strictBounds: true,
            },
            styles: styles,
        };
    }
}

export default {

    data: function () {
        return {
            loader: null,
            map: {
                instance: undefined,
                bounds: undefined,
                markerElements: [],
            },
        };
    },
    methods: {
        initMap: function () {
            if (!loader && this.vm.apiKey) {
                initLoader(this.vm.apiKey);
            }
            if (loader && !this.map.instance) {
                loader.load()
                    .then(() => {
                        this.createMap(getConfig(this.vm.hideControls));
                    })
                    .catch((e) => {
                        // do something
                    });
            }
        },
        createMap: function (options) {
            this.map.instance = new google.maps.Map(this.$el, options);
            this.map.bounds = new google.maps.LatLngBounds();
            this.loadMarkers();
            this.map.instance.addListener('tilesloaded', () => {
                this.identifyLogo();
            });
        },
        loadMarkers: function () {
            if (window.google && this.map.instance) {
                this.clearMarkers();
                this.map.bounds = new google.maps.LatLngBounds();
                this.addMarkers();
            }
        },
        clearMarkers: function () {
            this.map.markerElements.forEach(marker => {
                marker.setMap(null);
                google.maps.event.clearInstanceListeners(marker);
            });
            this.map.markerElements = [];
            this.map.bounds = null;
        },
        identifyLogo: function () {
            setTimeout(() => {
                const logo = this.$el.querySelector('a[target="_blank"]');
                logo.classList.add('gmap-logo');
                const logoParent = logo.parentNode;
                logoParent.classList.add('gmap-logo-wrapper');
            }, 500);
        }
    },
    created: function () {
        this.initMap();
    },
    beforeDestroy: function () {
        this.clearMarkers();
        this.map.instance = undefined;
        this.map.bounds = undefined;
    }

}