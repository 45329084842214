import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
        'text'
    ];
  }

  initialize() {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.element.classList[entry.target.scrollHeight > Math.ceil(entry.contentRect.height) ? 'add' : 'remove']('is-truncated');
      }
    });
    observer.observe(this.textTarget);
  }

  expand(e) {
    const button = e.currentTarget;
    const previousScrollPosition = window.scrollY;
    this.element.classList.toggle('is-expanded');
    button.setAttribute('aria-expanded', button.getAttribute('aria-expanded') !== 'true');
    window.scroll(window.scrollX, previousScrollPosition);
  }
};