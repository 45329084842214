export default {
    data: function() {
        return {
            isFiltersOpen: false,
            primaryFilterMenuElement: undefined,
            isSecondaryFilterMenuOpen: false
        }
    },
    computed: {
        filteringLabel() {
            if(this.isFiltersOpen) {
                return 'Back';
            }
            else if(this.isFiltering()) {
                return 'Filters On';
            }
            return 'Filters'; 
        }
    },
    methods: {
        initFilters: function() {
            this.primaryFilterMenuElement = this.$el.querySelector('[data-primary-menu]');
        },
        select: function(name, selectedValues, update) {
            this.state.filters[name] = selectedValues;
            if(update) this.update(true);
        },
        isFiltering: function()
        {
            if(this.$refs.filters) {
                return this.$refs.filters.some((filter) => filter.isFiltering);
            }
            return false;
        },
        isFieldFiltering: function(field)
        {
            if(this.$refs.filters) {
                let filter = this.$refs.filters.find((filter) => filter.vm.name == field.name);
                if(filter) return filter.isFiltering;
            }
            return false;
        },
        toggleSecondary: function(currentFilter) {
            this.isSecondaryFilterMenuOpen = !this.isSecondaryFilterMenuOpen;

            if(this.primaryFilterMenuElement.scrollHeight > this.primaryFilterMenuElement.clientHeight) {
                this.primaryFilterMenuElement.scrollTo(0, 0);
            }

            this.$refs.filters.forEach(filter => {
                if(filter != currentFilter)
                    filter.applyAndClose();                    
            });
        },
        forceFilterResize: function()
        {
            this.$refs.filters.forEach(filter => {
                filter.resize();                    
            });
        },
        toggleFilters: function() {
            this.isFiltersOpen = !this.isFiltersOpen;
            document.body.classList.toggle('is-filters-open');
            this.siteHeaderElement.dispatchEvent(new Event('SubNavToggled'));
        }
    }
}