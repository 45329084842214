import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {

  static get targets() {
    return [
      'caption'
    ];
  }

  initialize() {
    this.slider = new Splide(this.element, {
      perPage: 1,
      pagination : false,
      arrows : true,
      speed: 1200,
      waitForTransition: false,
    } ).mount();

    this.captionTargets.forEach((element, index) => {
      element.querySelector('[data-slide-index]').innerHTML = index + 1;
      element.querySelector('[data-slide-count]').innerHTML = this.captionTargets.length;
    });

    document.dispatchEvent(new CustomEvent('NewSplideInstance', {detail: {'splideElement': this.element}}));
  }
};