import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    const styles = getComputedStyle(this.element);
    const breakpoints = {
      oneItem: styles.getPropertyValue('--one-item-breakpoint'),
      twoItem: styles.getPropertyValue('--two-item-breakpoint'),
      threeItem: styles.getPropertyValue('--three-item-breakpoint'),
    };

    this.slider = new Splide(this.element, {
      gap: '2rem',
      perPage: 4,
      pagination : false,
      arrows : true,
      breakpoints: {
        [breakpoints.oneItem]: {
          perPage: 1,
        },
        [breakpoints.twoItem]: {
          perPage: 2,
        },
        [breakpoints.threeItem]: {
          perPage: 3,
        },
      }
    } ).mount();

    document.dispatchEvent(new CustomEvent('NewSplideInstance', {detail: {'splideElement': this.element}}));
  }
};