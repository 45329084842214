export default {
    data: function()
    {
        return {
            isOpen: false,
            isTransitioning: false,
            isDirty: false,
            inputsWrapper: undefined
        }
    },
    props: {
        isFiltersOpen: Boolean
    },
    computed: {
        allElement() {
            return this.vm.elements.find((el) => { return el.value.toLowerCase() === 'all'; });
        },
        isFiltering() {
            if(this.allElement)
                return this.selectedLabel != this.allElement.label;
            else
               return this.selectedCount > 0;
        }
    },
    mounted() {
        this.inputsWrapper = this.$el.querySelector('[data-inputs-wrapper]');
        this.resize();
        
        this.inputsWrapper.addEventListener('transitionrun', (e) => this.toggleTransitioning(e));
        this.inputsWrapper.addEventListener('transitionend', (e) => this.toggleTransitioning(e));
    },
    methods: {
        toggleTransitioning: function(e) {
            if(e.propertyName === 'max-height') {
                this.isTransitioning = !this.isTransitioning;
            }
        },
        resize: function() {
            if(this.$el.querySelector) {
                const observer = new ResizeObserver(entries => {
                    for (let entry of entries) {
                        entry.target.style.setProperty('--height', (entry.target.scrollHeight + 1) + "px");
                    }
                });
                observer.observe(this.inputsWrapper);
            }
        },
        applyAndClose: function() {
            if(this.isOpen) {
                this.isOpen = false;
                this.$emit('toggle-secondary', this);

                if(this.isDirty) {
                    this.emitSelect();
                    this.isDirty = false;
                }
            }
        },
        toggle: function() {
            this.$emit('toggle-secondary', this);
            if(!this.isOpen) {
                this.isOpen = true;
            }
            else {
                this.applyAndClose();
            }
        },
        emitSelect: function() {
            this.$emit('select-value', this.vm.name, this.selectedValues, true);
        },
        onChange: function() {
            let toUpdate = false;
            if(this.lastSelectedElement === this.allElement) {
                if(this.selectedCount > 1) {
                    this.clear();
                }
                else {
                    this.selectedValues = [this.vm.elements[1].value];
                }
                toUpdate = true;
            }
            else if(!this.hasSelected) {
                this.clear();
                toUpdate = true;
            }
            else if(this.allElement) {
                this.isDirty = true;
                this.selectedValues = this.selectedValues.filter((val) => { return val != this.allElement.value.toLowerCase(); })
            }
            else {
                this.isDirty = true;
            }

            if(toUpdate) {
                this.emitSelect();
            }
        },
        clear: function() {
            this.selectedValues = [];
            if(this.allElement) {
                this.selectedValues.push(this.allElement.value);
                this.$emit('select-value', this.vm.name, this.selectedValues, false);
            }
        }
    }
}