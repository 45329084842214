import createHTMLMapMarker from "../elements/overlayMarker.js";

export default {
   
    methods: {
      addMarkers: function () {

        var promises = [];

        this.vm.markers.forEach((marker) => {
          promises.push(this.addMarker(marker)); 
        });

        Promise.all(promises).then(() => {
          this.addZoomLimit();
          this.fitAndPanToBounds();
        });

      },
      addMarker: function(marker) {
        const latLng = new google.maps.LatLng(
          marker.position.lat,
          marker.position.lng
        );
        let markerElement = createHTMLMapMarker({
          latlng: latLng,
          map: this.map.instance,
          html: `<button class="map__marker__button ${marker.propertyCount == 0 ? 'map__marker__button--zero' : ''}" aria-label="View ${marker.propertyCount} ${marker.development} properties">
                  <svg class="map__marker__icon" aria-hidden="true">
                    <use xlink:href="/spritemap.svg#map-marker"></use>
                  </svg>
                  <svg class="map__marker__logo" aria-hidden="true">
                    <use xlink:href="/spritemap.svg#northern-logo-icon"></use>
                  </svg>
                  <span class="map__marker__count">${marker.propertyCount}</span>
                </button>
                <div class="map__marker__text">
                  ${this.vm.development ? '<h3 class="map__marker__title">${marker.development}</h3>' : ''}
                  ${this.vm.linkTitle ? 
                    `<span class="map__marker__link-text" aria-hidden="true">
                      ${this.vm.linkTitle}
                      <svg class="map__marker__link-text__icon" aria-hidden="true">
                        <use xlink:href="/spritemap.svg#arrow--stroke"></use>
                      </svg>
                    </span>`
                    : ''
                  }
                </div>`,
        });
        google.maps.event.addListener(markerElement, 'click', () => { 
          this.unSelectAllMarkers();
          markerElement.makeActive();
          this.map.instance.panTo(markerElement.getPosition());
          this.selectDevelopment(marker.developmentSlug); 
        });
        this.map.markerElements.push(markerElement);
        this.map.bounds.extend(latLng);
      },
      unSelectAllMarkers: function(fitToBounds) {
        this.map.markerElements.forEach((markerElement) => { markerElement.removeActive() });
        if(fitToBounds) {
          this.fitAndPanToBounds();
        }
      },
      selectDevelopment: function(developmentSlug) {
        this.$emit("select-development", developmentSlug);
      },
      fitAndPanToBounds: function() {
        this.map.instance.fitBounds(this.map.bounds, 60);
        this.map.instance.panToBounds(this.map.bounds);
      },
      addZoomLimit: function () {
        let map = this.map.instance;
        let markerCount = this.map.markerElements.length;
  
        google.maps.event.addListenerOnce(map, 'zoom_changed', function () {
          google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
            let maxZoom = 16;
            if (markerCount > 1) maxZoom = 17;
            if (map.getZoom() > maxZoom && map.initialZoom == true) {
              map.setZoom(maxZoom);
              map.initialZoom = false;
            }
          });
        });
        this.map.instance.initialZoom = true;
      }
    }
  }