import repo from '../services/developmentListingRepo';

export default {
    data: function() {
        return {
            isLoading: false,
            isDev: false,
            api: {},
            state: {
                map: false,
                filters: {}
            }
        }
    },
    methods: {
        initData: function(blockHelper) {
            this.isDev = document.documentElement.dataset.frontendEnvironment === 'true';
            this.api = this.isDev ? repo.apiDev : repo.apiLive;

            this.api.search(this.state, this.pageSize, true).then((response) => {
                
                blockHelper.assign(this, response.data);
                
                this.vm.searchFields.forEach((e) => {
                    this.state.filters[e.name] = e.defaultValues;
                });

            });
        },
        update: function(isSearch) {

            if(isSearch) {
                //must clear property list for splide lazy loading to work!
                this.vm.developments = [];
                this.vm.relatedProperties = {};
            }

            this.api.search(this.state, this.pageSize).then((response) => {

                this.vm.searchFields = response.data.searchFields;

                if(isSearch) {
                    this.vm.developments = response.data.developments;
                    this.vm.relatedProperties = response.data.relatedProperties;
                    this.forceFilterResize();
                    this.updateMapMarkers(response.data.map.markers);
                    this.resetMap();
                }
                else {
                    this.vm.developments = [...this.vm.developments, ...response.data.developments];
                }
                this.isLoading = false;
                if(!this.isDev) {
                    this.vm.searchFields = response.data.searchFields;
                }
                if(!isSearch) {
                    window.scroll(window.scrollX, this.previousScrollPosition);
                }
            });
        },
        sort: function(orderBy) {
            this.state.orderBy = orderBy;
            this.update(true);
        },
        clear: function() {

            var promises = [];
            this.$refs.filters.forEach(filter => {
                promises.push(filter.clear());                    
            });
            Promise.all(promises).then(() => 
                this.update(true)
            );
        }
    }
}